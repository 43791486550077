import React from 'react'
import Header from '../header'
import Footer from '../footer'

export function LayoutOne(props) {
  return (
    <div>
        <Header />
        {props.children}
        <Footer />

        <a className='whatsapp_area' href="https://wa.me/917688822144?text=Hi%20I%27m%20interested%20to%20buy%20I%27d">
            <i className="fa-brands fa-whatsapp"></i> Whatsapp Us
        </a>
    </div>
  )
}

export function LayoutTwo(props) {
  return (
    <div>
        <Header />
        {props.children}
        <Footer />
    </div>
  )
}

export function LayoutThree(props){
  return(
    <div>
      {props.children}
    </div>
  )
}
