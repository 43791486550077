import React from 'react'
import { panelSitesData } from '../../data'
import ContactForm from '../../components/contactForm'

function PanelSite() {
  return (
    <section className='panel_site'>
                <div className="container py-4">
            <h3 className="text-center heading">Panel Sites</h3>

            <div className='mt-5'>
                {panelSitesData.map((site_data, index) => {
                    return(
                        <div className="row panel_site_box my-3" key={index} onClick={() => {
                            let domainName = site_data.domain;
                            let protocol = window.location.protocol; // use the current protocol (http or https)
                            const fullLink = `${protocol}//${domainName}`;

                            window.open(fullLink)
                        }}>
                            <div className="col-md-2 col-3">
                                {/* <div className='d-flex justify-content-center mb-2'> */}
                                    <img src={`/assets/images/${site_data.image}`} alt={site_data.name} className='img-fluid' width={100}/>
                                {/* </div> */}
                            </div>
                            <div className="col-md-10 col-9">
                                <h6>{site_data.name}</h6>
                                <h6>{site_data.domain}</h6>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='mt-5'>
                <ContactForm />
            </div>
        </div>
    </section>
  )
}

export default PanelSite