import React from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from 'react-bootstrap/Carousel';

import Slider from "react-slick";
import ContactForm from '../../components/contactForm';
import { customers_data, joinButtonData, stats } from '../../data';
import { Link } from 'react-router-dom';

function Homepage() {
    const bannersBack = [1, 2, 3, 4, 5];

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 2000,
    };
  return (
    <section className='homepage'>
        <div className="hero-area">
            <div style={{maxWidth: "100vw", overflow: "hidden"}}>
                {/* <Slider {...settings}>
                    {bannersBack.map((item, index) => {
                        return(
                            <div key={index} className='d-flex justify-content-center'>
                                <img src={`/assets/images/banner_${item}.jpeg`} style={{objectFit: "cover", maxWidth: "100%", width: "700px", height: "auto"}} alt={`banner image ${item}`} className='img-fluid'/>
                            </div>
                        )
                    })}
                </Slider> */}
                <Carousel>
                {bannersBack.map((item, index) => {
                        return(
                            <Carousel.Item>
                                <div key={index} className='d-flex justify-content-center'>
                                <img src={`/assets/images/banner_${item}.jpeg`} style={{objectFit: "cover", maxWidth: "100%", width: "700px", height: "auto"}} alt={`banner image ${item}`} className='img-fluid'/>
                            </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                
            </div>
            <div className='d-flex justify-content-center align-items-center flex-column mt-5'>
                {joinButtonData.map((b_data, index) => {
                    return(
                        b_data.external ?
                        <a key={index} className='my-2 theme_btn' href={b_data.link} target='_blank'>{b_data.name}</a>
                        :
                        <Link key={index} className='my-2 theme_btn' to={b_data.link}>{b_data.name}</Link>
                    )
                })}
            </div>
        </div>

        <div className='about mt-4'>
            <h3 className='text-center heading'>About Us</h3>

            <div className='d-flex justify-content-center'>
                <p className='about-us-content text-center'>
                We provide various betting exchanges with more than 30 platforms to play on different types of sports , games , casino and binary markets We provide all the levels of Agency and client IDs Admin , Senior super ,Super Master Master , Player ID . We provide white label of all websites , Make your own website.
                </p>
            </div>
        </div>

        {/* <div className='multiple_text mt-4'>
            <div className='d-flex justify-content-center align-items-center'>
                <div class="wrapper">
                    <div class="static-txt">We Are India's</div>
                    <ul class="dynamic-txts">
                    <li><span>Biggest Betting Platform...</span></li>
                    <li><span>Best Service Provider...</span></li>
                    </ul>
                </div>
            </div>
        </div> */}

        <div className='stats mt-4'>
            <h3 className='text-center heading'>IMPORTANT STATISTICS</h3>

            <div className="container mt-4">
                <div className="row">
                    {stats.map((stat_data, index) => {
                        return(
                            <div className="col-md-3 stat_data" key={index}>
                                <div className='d-flex justify-content-center align-items-center flex-column'>
                                    <i className={`fa-solid ${stat_data.icon}`}></i>
                                    <h2 className='value'>{stat_data.value}</h2>
                                    <p className='helper_text'>{stat_data.h_text}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

        <div className='what_customers_say mt-4'>
            <h3 className='text-center heading'>WHAT CUSTOMERS SAY..</h3>
            <div className='mt-4'>
                <Carousel>
                {customers_data.map((c_data, index) => {
                        return(
                            <Carousel.Item>
                                <div className='d-flex justify-content-center' key={index}>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <h4 className='c_name'>{c_data.name}</h4>
                                        <h6 className='c_desg'>{c_data.designation}</h6>
                                        <p className='c_feedback text-center'>{c_data.feedback}</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </div>
            
        </div>
        
        <ContactForm />
    </section>
  )
}

export default Homepage