import React from 'react'
import { refillRules } from '../../data'
import RulesCard from '../../components/rulesCard'
import ContactForm from '../../components/contactForm'

function RefillRules() {
  return (
    <section className='rules'>
        <div className='py-5'>
            <h3 className='text-center heading'>REFILL RULES</h3>

            <div className="container mt-5">
                <RulesCard data={refillRules}/>
            </div>
            <div className='mt-5'>
                <ContactForm />
            </div>
        </div>
    </section>
  )
}

export default RefillRules