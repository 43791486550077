import './App.css';
import './style/style.css'
import RoutesObj from './components/routes';
import { useEffect, useState } from 'react';


function App() {
  const [is18Plus, setIs18Plus] = useState(false)

  useEffect(() => {
    let is18Plus = JSON.parse(localStorage.getItem("is18Plus"))
    if(is18Plus){
      setIs18Plus(true)
    }
  }, [])
   return (
    <>
      {!is18Plus ?
        <>
          <RoutesObj />
        </>
      :
        <>
          <div class="warning-container">
              <h1>Adults Only</h1>
              <p>This content is intended for mature audiences. Viewer discretion is advised.</p>
              <a href="your_actual_content.html" class="proceed-btn">Proceed</a>
          </div>
        </>
      }

     
    </>
  );
}

export default App;
