import React from 'react'
import { demoSitesData } from '../../data'
import ContactForm from '../../components/contactForm'

function ClientSite() {
  return (
    <section className='client_site'>
        <div className="container py-4">
            <h3 className="text-center heading">Demo Sites / Client Sites</h3>

            <div className='mt-5'>
                {demoSitesData.map((site_data, index) => {
                    return(
                        <div className="row client_site_box my-3" key={index} onClick={() => {
                            window.open(site_data.name)
                        }}>
                            <div className="col-md-10 col-8">
                                <div className='d-flex justify-content-center mb-2'>
                                    <img src={`/assets/images/${site_data.image}`} alt={site_data.name} className='img-fluid' width={100}/>
                                </div>
                                <h6>{site_data.name}</h6>
                            </div>
                            <div className="col-md-2 col-4">
                                <h6>ID - {site_data.id}</h6>
                                <h6>Pass - {site_data.pass}</h6>
                            </div>
                        </div>
                    )
                })}
            </div>


            <div className='mt-5'>
                <ContactForm />
            </div>
        </div>
    </section>
  )
}

export default ClientSite