import React, { useState } from 'react'
import {Modal, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function AdultModal() {
    const [isAbove18, setIsAbove18] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    const navigate = useNavigate()

    const proceedCheck = () => {
        if(!isAbove18){
            setErrorMsg("Please agree to this")
            return
        }
        localStorage.setItem("isAbove18", JSON.stringify(true))
        window.location.reload()
    }
  return (
    <Modal show={true} className='adult-modal'>
        <Modal.Header>
        <Modal.Title>AGE RESTRICTION DISCLAIMER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                The content beyond this point is intended for mature audiences aged 18 and above. Prepare yourself for a journey into the realms of explicit and adult-oriented material. Viewer discretion is advised, and if you're not of legal age or uncomfortable with such content, it's time to hit the brakes and navigate elsewhere. Remember, consent and respect are crucial even in the virtual world. Enjoy responsibly!
            </p>

            <div className='mt-4'>
                <div className="form-check">
                    <input className="form-check-input" checked={isAbove18} onChange={(e) => {
                        setIsAbove18(e.target.checked)
                    }} type="checkbox" value="" id="flexCheckChecked"/>
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        I confirm that I am 18 years of age or older.
                    </label>
                </div>
                {errorMsg != "" &&
                    <p className='text-danger'>{errorMsg}</p>
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
        <Button className='theme_btn' size='small' disabled={errorMsg != ""} onClick={proceedCheck}>
            Proceed
        </Button>
        </Modal.Footer>
    </Modal>

  )
}

export default AdultModal