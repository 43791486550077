import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

function Header() {
  const [expanded, setExpanded] = useState(false)

  const handleNavbarClose = () => {
    setExpanded(false)
  }
  return (
    <Navbar expand="lg" expanded={expanded}>
      <Container>
        <Link to="/"><img src='/assets/images/miraj_logo.png' alt='Miraj Logo' width={200}/></Link>
        <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0"
          >
            <Link eventKey="1" className='nav-link' to="/" onClick={handleNavbarClose}>Home</Link>
            <Link className='nav-link' to="/client-site" onClick={handleNavbarClose}>Client Site</Link>
            <Link className='nav-link' to="/panel-site" onClick={handleNavbarClose}>Panel Site</Link>
            <NavDropdown title="Rules" id="navbarScrollingDropdown">
              <Link className='dropdown-item' to="/withdrawl-rules" onClick={handleNavbarClose}>Withdrawl Rules</Link>
              <Link className='dropdown-item' to="/refill-rules" onClick={handleNavbarClose}>Refill Rules</Link>
              <Link className='dropdown-item' to="/online-gaming-rules" onClick={handleNavbarClose}>Online Gaming Rules</Link>
            </NavDropdown>
            <Link className='nav-link' to="/why-join-us" onClick={handleNavbarClose}>Why Join Us?</Link>
            <Link className='nav-link' to="/contact-us" onClick={handleNavbarClose}>Contact Us</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;