import React from 'react'
import RulesCard from '../../components/rulesCard'
import { gamingRules } from '../../data'
import ContactForm from '../../components/contactForm'

function OnlineGamingRules() {
  return (
    <section className='rules'>
        <div className='py-5'>
            <h3 className='text-center heading'>ONLINE GAMING RULES</h3>

            <div className="container mt-5">
                <RulesCard data={gamingRules}/>
            </div>

            <div className='mt-5'>
                <ContactForm />
            </div>
        </div>
    </section>
  )
}

export default OnlineGamingRules