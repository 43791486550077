import React from 'react'
import RulesCard from '../../components/rulesCard'
import { withdrawlRules } from '../../data'
import ContactForm from '../../components/contactForm'

function WithdrawlRules() {
  return (
    <section className='rules'>
        <div className='py-5'>
            <h3 className='text-center heading'>WITHDRAWL RULES</h3>

            <div className="container mt-5">
                <RulesCard data={withdrawlRules}/>
            </div>

            <div className='mt-5'>
                <ContactForm />
            </div>
        </div>
    </section>
  )
}

export default WithdrawlRules