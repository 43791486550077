import React,{useEffect} from "react";
import {Routes,Route,BrowserRouter, Navigate} from "react-router-dom";
import { useLocation} from 'react-router-dom'
import {LayoutOne, LayoutThree, LayoutTwo} from "../layouts"
import Homepage from "../../pages/homepage";
import WhyJoinUs from "../../pages/whyJoinUs";
import WithdrawlRules from "../../pages/withdrawlRules";
import RefillRules from "../../pages/refillRules";
import OnlineGamingRules from "../../pages/onlineGamingRules";
import ContactUs from "../../pages/contactUs";
import ClientSite from "../../pages/clientSite";
import panelSite from "../../pages/panelSite";
import PanelSite from "../../pages/panelSite";
import AdultModal from "../adultModal";

function _ScrollToTop(props) {
  
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

const ScrollToTop =(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Layout {...rest} page={rest.page}>
      <Component {...rest} />
    </Layout>
  );
}

const RoutesObj = (props) => {
  return (
    <BrowserRouter>
      <ScrollToTop>
          <Routes>
            {JSON.parse(localStorage.getItem("isAbove18")) ? 
              <>
                <Route exact path="/" element={<RouteWrapper exact component={Homepage} layout={LayoutOne} {...props}/>} />
                <Route exact path="/client-site" element={<RouteWrapper exact component={ClientSite} layout={LayoutOne} {...props}/>} />
                <Route exact path="/panel-site" element={<RouteWrapper exact component={PanelSite} layout={LayoutOne} {...props}/>} />
                <Route exact path="/why-join-us" element={<RouteWrapper exact component={WhyJoinUs} layout={LayoutOne} {...props}/>} />
                <Route exact path="/withdrawl-rules" element={<RouteWrapper exact component={WithdrawlRules} layout={LayoutOne} {...props}/>} />
                <Route exact path="/refill-rules" element={<RouteWrapper exact component={RefillRules} layout={LayoutOne} {...props}/>} />
                <Route exact path="/online-gaming-rules" element={<RouteWrapper exact component={OnlineGamingRules} layout={LayoutOne} {...props}/>} />
                <Route exact path="/contact-us" element={<RouteWrapper exact component={ContactUs} layout={LayoutOne} {...props}/>} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
              :
              <>
                <Route exact path="/adult" element={<RouteWrapper exact component={AdultModal} layout={LayoutThree} {...props}/>} />
                <Route path="*" element={<Navigate to="/adult" replace />} />
              </>
            }
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    )
  }
export default RoutesObj;