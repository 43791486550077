import React from 'react'
import ContactForm from '../../components/contactForm'

function ContactUs() {
  return (
    <section className='contact-us-page'>
        <div className="container py-4">
            <ContactForm />
        </div>
    </section>
  )
}

export default ContactUs