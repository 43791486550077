import React from 'react'
import { whyJoinUsData } from '../../data'
import RulesCard from '../../components/rulesCard'

function WhyJoinUs() {
  return (
    <section className='why-join-us'>
        <div className='py-5'>
            <h3 className='text-center heading'>WHY JOIN US</h3>

            <div className="container mt-5">
                <RulesCard data={whyJoinUsData}/>
            </div>
        </div>
    </section>
  )
}

export default WhyJoinUs