import React from 'react'

function RulesCard(props) {
    const {data} = props
  return (
    <>
        {data.map((item, index) => {
            return(
                <div className='rules_card' key={index} dangerouslySetInnerHTML={{__html: item}}
                />
            )
        })}
    </>
  )
}

export default RulesCard